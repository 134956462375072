import './App.css';

function App() {
  const images = require.context('./svg', false, /\.svg$/);
  const imagePaths = images.keys().sort(); // ordena as chaves das imagens

  return (
    <div className="container">
      {imagePaths.map((path, index) => (
        <div key={`image-${index}`}>
          <object type="image/svg+xml" data={images(path)} style={{ width: '100%', maxHeight: '100vh' }} preserveAspectRatio="xMidYMid meet" />
        </div>
      ))}
    </div>
  );
}

export default App; 